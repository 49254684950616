.education-main {
  width: 100%;
}

.basic-education {
  margin-left: 5%;
  margin-right: 5%;
}

.heading-div {
  display: flex;
  justify-content: space-between; /* Distribute space between the elements */
  align-items: center; /* Align items vertically in the center */
  margin-top: 40px; /* Add top margin to create space between the image and the header */
}

.heading-div > * {
  flex: 1;
}

.heading-img-div {
  align-items: center;
  justify-content: center;
}

.heading-text-div {
  text-align: center; /* Center text */
}

.heading-img-div > * {
  max-width: 100%;
  height: auto;
  border-radius: 15px; /* Add rounded corners to the image */
}

.heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 80px;
}

.heading-sub-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center; /* Center subtext */
  margin-bottom: 10px;
}

@media (max-width: 1380px) {
  .heading-text {
    font-size: 50px;
    margin-top: 80px;
  }
  .heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .heading-sub-text {
    font-size: 20px;
  }

  .heading-div {
    flex-direction: column;
  }

  .experience-header-detail-text {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
}
.education-card-link {
  text-decoration: none;
  color: inherit;
  display: block; /* Make the entire block clickable */
}

.education-card-link:hover .heading-div {
  transform: scale(1.05); /* Optional: Add a hover effect to the card */
  transition: transform 0.2s ease-in-out;
}
